<template>
  <div class="account-container">
    <el-card shadow="never" class="my-card">
      <div class="title">
        <span>{{ $t('my_account_msg1') }}</span>
        <span>{{ $t('home_page_title2') }} {{ time }}</span>
      </div>
      <el-table size="small" :data="tableData" style="width: 100%">
        <el-table-column
          prop="currency"
          :label="$t('home_page_title5')"
        ></el-table-column>
        <el-table-column
          prop="balance"
          :label="$t('home_page_title6')"
        ></el-table-column>
        <el-table-column prop="availableAmount" :label="$t('my_account_msg2')">
          <template slot-scope="scope">
            <span>{{ $utils.formatAmount(scope.row.availableAmount) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="frozenAmount" :label="$t('my_account_msg3')">
          <template slot-scope="scope">
            <span>{{ $utils.formatAmount(scope.row.frozenAmount) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="action" :label="$t('home_page_title8')">
          <template slot-scope="scope">
            <a
              @click="
                $router.push(`/check-detail?currency=${scope.row.currency}`)
              "
            >
              {{ $t('home_page_title9') }}
            </a>
            &nbsp; &nbsp;
            <a @click="$router.push(`/withdraw`)">
              {{ $t('home_page_title10') }}
            </a>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { getWalletTableList } from 'views/main/my-account/api';
import moment from 'moment/moment';
export default {
  name: 'my-account',
  data() {
    return {
      time: '',
      tableData: [],
    };
  },
  mounted() {
    this.time = moment().format('YYYY-MM-DD HH:mm:ss');
    this._getWalletTableList();
  },
  methods: {
    async _getWalletTableList() {
      const _params = {};
      this.tableData = await getWalletTableList(_params);
    },
  },
};
</script>

<style scoped lang="scss">
.account-container {
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
  display: flex;
  justify-content: center;
  align-items: center;
  .my-card {
    width: 95%;
    height: 95%;
    .title {
      height: 40px;
      border-bottom: 1px solid #e9e9e9;
      display: flex;
      align-items: baseline;
      & > span:first-child {
        font-weight: 500;
        font-size: 16px;
      }
      & > span:nth-child(2) {
        color: rgba(0, 0, 0, 0.45);
        font-size: 12px;
        margin-left: 20px;
      }
    }
  }
}
</style>
